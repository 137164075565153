import { Container } from '@mui/material';

function NotFound() {
	return (
		<Container sx={{ my: 5 }}>
			<h1>Page not found</h1>
		</Container>
	);
}

export default NotFound;
